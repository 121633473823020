import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  errorNotification: "",
};

export const errorNotificationSlice = createSlice({
  name: "errorNotification",
  initialState,
  reducers: {
    setErrorNotification: (state, action) => {
      state.errorNotification = action.payload.errorNotification;
    },
  },
});

export const { setErrorNotification } = errorNotificationSlice.actions;

export const errorNotificationState = (state) => state.errorNotification;

export default errorNotificationSlice.reducer;
