import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Slide,
  TablePagination,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  Container,
} from "@mui/material";
import React from "react";
import { createSvgIcon } from "@mui/material/utils";
import {
  DataGrid,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  gridSortedRowIdsSelector,
  GridToolbarContainer,
  gridExpandedSortedRowIdsSelector,
  useGridApiContext,
  GridToolbar,
} from "@mui/x-data-grid";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";

import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getUserAccountsAsync,
  getUserAttendanceAsync,
  adminAttendanceState,
  setSelectIndex,
  clearCollection,
  setPreviewCollection,
  setselectLead,
} from "./reduxSlice";
import { userState } from "../Users/reduxSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getRowsFromCurrentPage = ({ apiRef }) =>
  gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);

const getUnfilteredRows = ({ apiRef }) => gridSortedRowIdsSelector(apiRef);

const getFilteredRows = ({ apiRef }) =>
  gridExpandedSortedRowIdsSelector(apiRef);

const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  "SaveAlt"
);

function CustomToolbar() {
  const apiRef = useGridApiContext();

  const handleExport = (options) => apiRef.current.exportDataAsCsv(options);

  const buttonBaseProps = {
    color: "primary",
    size: "small",
    startIcon: <ExportIcon />,
  };

  return (
    <GridToolbarContainer className=" p-3 justify-content-end border-bottom">
      {/* <Button
        {...buttonBaseProps}
        onClick={() =>
          handleExport({ getRowsToExport: getRowsFromCurrentPage })
        }
      >
        Current page rows
      </Button> */}
      <Button
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getFilteredRows })}
        sx={{ textTransform: "capitalize" }}
      >
        Filtered Attendances CSV
      </Button>
      <Button
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}
        sx={{ textTransform: "capitalize" }}
      >
        Export CSV
      </Button>
    </GridToolbarContainer>
  );
}

const headers = [
  { headerName: "Status", field: "punchStatus", width: 200 },
  { headerName: "Live Location", field: "liveLocation", width: 350 },
  { headerName: "Last Check-in", field: "punchIn", width: 350 },
  { headerName: "Last Check-out", field: "punchOut", width: 350 },
];

export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openImage, setImageOpen] = React.useState(false);
  const [imagePreview, setImagePreview] = React.useState(false);
  const userAvlUID = window.localStorage.getItem("userAvlUID");
  const {
    usersAccountList,
    userAttendanceCollection,
    loading,
    selectLead,
    index,
    previewImageCollection,
    supervisorAttendanceCollection
  } = useSelector(adminAttendanceState);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(setSelectIndex({ value: "" }));
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleImageClose = () => {
    setImageOpen(false);
  };
  const handleImageOpen = () => {
    setImageOpen(true);
  };

  const handlePreviewClose = () => {
    setImagePreview(false);
  };
  const handlePreviewOpen = () => {
    setImagePreview(true);
  };

  React.useEffect(() => {
    dispatch(getUserAccountsAsync({ userAvlUID, navigate }));
    return () => {
      dispatch(clearCollection({}));
    };
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Box>
            <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell align="center">Email</StyledTableCell>
                    <StyledTableCell align="center">Phone No.</StyledTableCell>
                    <StyledTableCell align="center">Role</StyledTableCell>
                    <StyledTableCell align="center">View</StyledTableCell>
                    <StyledTableCell align="center">
                      View Images
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersAccountList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .filter((item)=>{
                      return item.role === "supervisor"
                    })
                    .map((row, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell component="th" scope="row">
                          {row.username}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.number}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.role}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <IconButton
                            onClick={() => {
                              dispatch(setSelectIndex({ value: row.id }));
                              dispatch(getUserAttendanceAsync({}));
                              handleOpen();
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <IconButton
                            onClick={() => {
                              dispatch(setSelectIndex({ value: row.id }));
                              dispatch(getUserAttendanceAsync({}));
                              handleImageOpen();
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[ 10, 15]}
              component="div"
              count={usersAccountList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Grid>

        {/* Attendance */}
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Attendances Report
              </Typography>
            </Toolbar>
          </AppBar>
          <Grid item xs={12}>
            <div style={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={userAttendanceCollection}
                columns={headers}
                loading={loading}
                getRowId={(userAttendanceCollection) =>
                  Math.random().toString()
                }
                slots={{ toolbar: CustomToolbar }}
                pageSizeOptions={[10]}
                className="px-3"
                initialState={{
                  ...userAttendanceCollection.initialState,
                  filter: {
                    ...userAttendanceCollection.initialState?.filter,
                  },
                  pagination: {
                    ...userAttendanceCollection.initialState?.pagination,
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
              />
            </div>
          </Grid>
        </Dialog>

        <Dialog
          fullScreen
          open={openImage}
          onClose={handleImageClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleImageClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Attendances Report
              </Typography>
            </Toolbar>
          </AppBar>
          <Container className="mt-3">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Location</StyledTableCell>
                    <StyledTableCell align="left">Check Out</StyledTableCell>
                    <StyledTableCell align="right">View Photo</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userAttendanceCollection
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell component="th" scope="row">
                          {row.liveLocation}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row.punchOut}
                        </StyledTableCell>
                        <StyledTableCell align="right" className="d-flex">
                          <IconButton
                            onClick={() => {
                              handlePreviewOpen()
                              dispatch(
                                setPreviewCollection({
                                  value: row.galleryCollection,
                                })
                              );
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={userAttendanceCollection.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Container>
        </Dialog>

        <Dialog
          open={imagePreview}
          onClose={handlePreviewClose}
          TransitionComponent={Transition}
        >
          <Container className="my-3">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handlePreviewClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Swiper
                  navigation={true}
                  modules={[Navigation]}
                  className="mySwiper"
                >{previewImageCollection?.map((item,index)=>{
                  return(
                    <SwiperSlide key={index}>
                      <img src={item} className="img-fluid"/>
                    </SwiperSlide>
                  )
                })}
                </Swiper>
              </Grid>
            </Grid>
          </Container>
        </Dialog>
      </Grid>
    </>
  );
}
