import { auth, db, storage } from "../../../firebase/config";
import { createUserWithEmailAndPassword , deleteUser } from "firebase/auth";
import {
  doc,
  setDoc,
  getDoc,
  collection,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";

export function createUser(email, password) {
  return createUserWithEmailAndPassword(auth, email, password);
}

export function createUserData(
  id,
  username,
  password,
  email,
  designation,
  number,
  address,
  city,
  state,
  country,
  role,
  reportingManager
) {
  return setDoc(doc(db, "users", id), {
    id: id,
    username: username,
    password: password,
    email: email,
    designation: designation,
    number: number,
    address: address,
    city: city,
    state: state,
    country: country,
    role: role,
    reportingManager: reportingManager
  })
}

export function uploadfile(id, file,location) {
  return new Promise((resolve, reject) => {
    const fileName = file.name;
    const storageRef = ref(storage, `/` + id + `/`+ location +`/` + fileName);
    const metadata = {
      contentType: null,
    };
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
}

export function fetchData() {
  return getDocs(collection(db, "users"));
}

export function deleteUserData(id) {
  return deleteDoc(doc(db, "users", id));
}
