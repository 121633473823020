import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableCel,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Slide,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Box,
  LinearProgress,
  TablePagination,
} from "@mui/material";
import "./App.css";

import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import UserForm from "./UserForm/App";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { createUserAsync, fetchDataAsync, deleteUserAsync } from "./reduxSlice";

import { useDispatch, useSelector } from "react-redux";
import { clearUserForm } from "./reduxSlice";
import { userState } from "./reduxSlice";
import { useNavigate } from "react-router-dom";
import { setErrorNotification } from "../Errors/reduxSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomizedTables() {
  const dispatch = useDispatch();
  const { userCollection, loading, username, email, password, role, fetchloading } =
    useSelector(userState);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    dispatch(fetchDataAsync({navigate}));
    return () => {
      dispatch(clearUserForm({navigate}));
    };
  }, [dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (username === "" || password === "" || email === "" || role === "") {
      dispatch(setErrorNotification({errorNotification: "Provide your name, email address, password, and role in the essential areas below."}))
    }else
    {
      dispatch(
        createUserAsync({
          email,
          password,
          navigate,
        })
        
      );
      dispatch(fetchDataAsync({navigate}));
      setOpen(false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(fetchDataAsync({navigate}));
    dispatch(clearUserForm({}));
  };

  function handleDelete(id) {
    dispatch(deleteUserAsync({ id,navigate }));
  }

  return (
    <>
      <Container>
        {fetchloading ? (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        ) : (
          <Box>
            <Button
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
              className="my-2"
              variant="contained"
              sx={{ textTransform: "capitalize" }}
            >
              Add User
            </Button>
            <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell align="center">Email</StyledTableCell>
                    <StyledTableCell align="center">Phone No.</StyledTableCell>
                    <StyledTableCell align="center">Role</StyledTableCell>
                    <StyledTableCell align="center">Delete</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userCollection
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell component="th" scope="row">
                          {row.username}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.number}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.role}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {/* <IconButton>
              <EditIcon />
            </IconButton> */}
                          <IconButton
                            onClick={() => {
                              console.log(row.id);
                              handleDelete(row.id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={userCollection.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Create User Form
              </Typography>
              <Button autoFocus color="inherit" onClick={submitHandler}>
                Save
              </Button>
            </Toolbar>
          </AppBar>
          <UserForm />
        </Dialog>
      </Container>
    </>
  );
}
