import * as React from "react";
import {
  Grid,
  Paper,
  Typography,
  Box,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
  TextField,
} from "@mui/material";
import { Form } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import Orders from "./CheckIn";
import AdminOrders from "./AdminCheckInStatus";
import { useDispatch, useSelector } from "react-redux";
import {
  leadManagerUserState,
  getLeadsAsync,
  getExcelLeadsAsync,
  clearLeadCollection,
} from "../AddLead/reduxSlice";
import {
  setPunchStatus,
  setLiveLocation,
  setPuchIn,
  setPuchOut,
  setKMStart,
  setKMEnd,
  attendanceState,
  punchInAsync,
  punchOutAsync,
  getAttendanceAsync,
  clearAttendanceState,
  getGeoLocationAsync,
  setGeoLocation,
  setPhotoStatus,
  clearKM
} from "../Attendance/reduxSlice";
import { fileUploadAsync, setPhoto } from "../AddLead/reduxSlice";
import { loginState } from "../Login/reduxSlice";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function App() {
  const dispatch = useDispatch();
  const { leadCollection } = useSelector(leadManagerUserState);
  const { photoStatus } = useSelector(attendanceState);
  const statusToken = window.localStorage.getItem("statusToken");
  const userAvlRole = window.localStorage.getItem("userAvlRole");
  const userAvlUID = window.localStorage.getItem("userAvlUID");

  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  React.useEffect(() => {
    const userAvlUID = window.localStorage.getItem("userAvlUID");
    const statusToken = window.localStorage.getItem("statusToken");
    dispatch(getLeadsAsync({ userAvlUID, navigate }));
    dispatch(getExcelLeadsAsync({ userAvlUID, navigate }));
    dispatch(getGeoLocationAsync({}));
    dispatch(setPhotoStatus({ value: false }));
    if (statusToken) {
      dispatch(setPunchStatus({ value: "Active" }));
    }
    {
      dispatch(setPunchStatus({ value: "Not Active" }));
    }
    dispatch(getAttendanceAsync({ userAvlUID }));
    return () => {
      dispatch(clearLeadCollection({}));
      dispatch(clearAttendanceState({}));
    };
  }, [dispatch, navigate]);

  const geoLocationFunction = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      window.localStorage.setItem("latitude", position.coords.latitude);
      window.localStorage.setItem("longitude", position.coords.longitude);
    });
  };

  function punch() {
    geoLocationFunction();
    dispatch(getGeoLocationAsync({}));
    if (statusToken) {
      dispatch(setPuchOut({ value: new Date() }));
      dispatch(setPunchStatus({ value: "Not Active" }));
      dispatch(fileUploadAsync({ userAvlUID, statusToken }));
    } else {
      dispatch(setPuchIn({ value: new Date() }));
      dispatch(setPunchStatus({ value: "Active" }));
      dispatch(fileUploadAsync({ userAvlUID, statusToken }));
    }
    setShow(false);
  }

  const previewFiles = (evt) => {
    var files = evt.target.files;

    for (let i = 0; i < evt.target.files.length; i++) {
      const newImage = evt.target.files[i];

      newImage["id"] = Math.random();
      dispatch(setPhoto({ newImage }));
    }
  };

  function UploadImage() {
    const userAvlUID = window.localStorage.getItem("userAvlUID");
    setShow(false);
  }

  return (
    <>
      <Grid container spacing={3}>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              Today’s Leads
            </Typography>
            <Typography component="p" variant="h4">
          ---
            </Typography>
            <Typography
              color="text.secondary"
              variant="p"
              component="p"
              className="mt-2"
              sx={{ flex: 1 }}
            >
              {moment().format("MMMM Do YYYY")}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              This Month Leads
            </Typography>
            <Typography component="p" variant="h4">
              --
            </Typography>
            <Typography
              color="text.secondary"
              variant="p"
              component="p"
              className="mt-2"
              sx={{ flex: 1 }}
            >
              {moment().format("MMMM Do YYYY")}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              Total Leads
            </Typography>
            <Typography component="p" variant="h4">
              {leadCollection.length}
            </Typography>
            <Typography
              color="text.secondary"
              variant="p"
              component="p"
              className="mt-2"
              sx={{ flex: 1 }}
            >
              {moment().format("MMMM Do YYYY")}
            </Typography>
          </Paper>
        </Grid>
        {userAvlRole === "supervisor" ? (
          <>
            <Grid item xs={12} md={4} lg={3}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  component="h2"
                  variant="h6"
                  color="primary"
                  gutterBottom
                >
                  Attendance
                </Typography>
                <Button
                  variant="contained"
                  onClick={handleShow}
                  className="py-2"
                >
                  {statusToken ? "Check-Out" : "Check-In"}
                </Button>
                <Typography
                  color="text.secondary"
                  variant="p"
                  component="p"
                  className="mt-2"
                  sx={{ flex: 1 }}
                >
                  {moment().format("MMMM Do YYYY")}
                </Typography>
              </Paper>
            </Grid>
          </>
        ) : (
          ""
        )}
        {/* Recent Orders */}
        {userAvlRole === "supervisor" ? (
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <Orders />
            </Paper>
          </Grid>
        ) : (
          <Grid item xs={12}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
            <AdminOrders />
          </Paper>
        </Grid>
        )}
      </Grid>

      <Dialog
        open={show}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Lead Manager Form
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="form"
          noValidate
          sx={{ mt: 3, px: 4, pb: 4, minWidth: 375 }}
        >
          <Grid container spacing={2}>
          <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="km"
                label={statusToken ? "Km End" : "Km Start"}
                name="km"
                onChange={(e) => {
                  if(statusToken){
                    dispatch(setKMEnd({ value: e.target.value }));
                  }else{
                    dispatch(setKMStart({ value: e.target.value }));
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Form.Group controlId="uploadImage" className="mb-2">
                <Form.Label>Upload Images</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={(e) => {
                    previewFiles(e);
                  }}
                />
              </Form.Group>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={punch}
              >
                 {statusToken ? "Check-Out" : "Check-In"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
}
