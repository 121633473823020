import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dashboard from "../Component/Dashboard/App";
import { loginState } from "../Component/Login/reduxSlice";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { setGeoLocation } from "../Component/Attendance/reduxSlice";
export default function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userAvl } = useSelector(loginState);
  React.useEffect(() => {
    if (!userAvl) {
      navigate("/login");
    }else{
      navigate("/dashboard")
    }
    geoLocationFunction()
  }, []);
  const geoLocationFunction = ()=>{
    navigator.geolocation.getCurrentPosition(function (position) {
      window.localStorage.setItem("latitude", position.coords.latitude)
      window.localStorage.setItem("longitude", position.coords.longitude)
    });
  }
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    </>
  );
}
