import * as React from "react";
import { styled } from "@mui/material/styles";
import "./App.css";
import { Modal } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableCel,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Slide,
  Grid,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  Container,
  Divider,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TablePagination,
  Box,
} from "@mui/material";
import { Form } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import FormBuilder from "../FormBuilder/App";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LeadForm from "./UserForm/App";
import LinearProgress from "@mui/material/LinearProgress";
import {
  setID,
  setUserFormModalFor,
  clearLeadForm,
  createLeadAsync,
  fileUploadAsync,
  setSelectedIndex,
  getLeadsAsync,
  getExcelLeadsAsync,
  leadManagerUserState,
  clearLeadCollection,
  deleteLeadsAsync,
  setUserFormModal,
  setCustomerName,
  setEmail,
  setDate,
  setNumber,
  setCity,
  setWhen,
  setLookingFinance,
  setInterested,
  setPinCode,
  setExcelCollection,
  excelUploadAsync,
  clearExcelCollection

} from "./reduxSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginState } from "../Login/reduxSlice";
import DownloadingIcon from "@mui/icons-material/Downloading";
import moment from "moment";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const columns = [
  { field: "customerName", headerName: "Name", width: 130 },
  { field: "email", headerName: "Email", width: 130 },
  {
    field: "city",
    headerName: "City",
    width: 90,
  },
  {
    field: "pinCode",
    headerName: "Pin Code",
    width: 90,
  },
  {
    field: "date",
    headerName: "Date",
    width: 160,
  },
];

export default function CustomizedTables() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { leadCollection, loading, loadingLeads, userFormModal, excelFetch } =
    useSelector(leadManagerUserState);
  const [excelModal, setExcelModal] = React.useState(false);

  const handleExcelClose = () => {
    setExcelModal(false);
    dispatch(clearExcelCollection({}))
  };
  const handleExcelOpen = () => {
    setExcelModal(true);
  };

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    const userAvlUID = window.localStorage.getItem("userAvlUID");
    dispatch(setUserFormModal({}));
    dispatch(getLeadsAsync({ userAvlUID, navigate }));
    dispatch(getExcelLeadsAsync({ userAvlUID, navigate }));
    dispatch(clearLeadForm({}));
    dispatch(setUserFormModalFor({ value: "Add" }));
  };
  const handleShow = () => {
    dispatch(setUserFormModal({}));
  };

  function handleDeleteLead(id) {
    const userAvlUID = window.localStorage.getItem("userAvlUID");
    dispatch(deleteLeadsAsync({ userAvlUID, id, navigate }));
  }
  function handleEditLead(id, index) {
    const userAvlUID = window.localStorage.getItem("userAvlUID");
    dispatch(setID({ id: id }));
    dispatch(setSelectedIndex({ index, index }));
    dispatch(
      setCustomerName({ customerName: leadCollection[index].customerName })
    );
    dispatch(setEmail({ email: leadCollection[index].email }));
    dispatch(setNumber({ number: leadCollection[index].number }));
    dispatch(setDate({ date: leadCollection[index].date }));
    dispatch(setCity({ city: leadCollection[index].city }));
    dispatch(setPinCode({ value: leadCollection[index].pinCode }));
    dispatch(setWhen({ when: leadCollection[index].when }));
    dispatch(setInterested({ interested: leadCollection[index].interested }));
    dispatch(
      setLookingFinance({
        lookingFinance: leadCollection[index].lookingFinance,
      })
    );
    dispatch(setUserFormModal({}));
    dispatch(setUserFormModalFor({ value: "Edit" }));
  }
  const previewFiles = (evt) => {
    var files = evt.target.files;

    for (let i = 0; i < evt.target.files.length; i++) {
      const excel = evt.target.files[i];
      const excelName = evt.target.files[i].name;
      const uploadDate = new Date().toString();

      excel["id"] = Math.random();
      dispatch(setExcelCollection({ excelName,uploadDate, excel }));
    }
  };

  function uploadExcel() {
    const userAvlUID = window.localStorage.getItem("userAvlUID");
    dispatch(excelUploadAsync({ userAvlUID }));
    setExcelModal(false);
  }

  React.useEffect(() => {
    const userAvlUID = window.localStorage.getItem("userAvlUID");
    dispatch(getLeadsAsync({ userAvlUID, navigate }));
    dispatch(getExcelLeadsAsync({ userAvlUID, navigate }));
    return () => {
      dispatch(clearLeadCollection({}));
      dispatch(clearLeadForm({}));
    };
  }, [dispatch]);

  return (
    <>
      <Container className="position-relative">
        {loadingLeads ? (
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
        ) : (
          <Box>
            <Button
              startIcon={<AddIcon />}
              onClick={handleShow}
              className="my-2"
              variant="contained"
              sx={{ textTransform: "capitalize" }}
            >
              Add Lead
            </Button>
            <Button
              startIcon={<AddIcon />}
              onClick={handleExcelOpen}
              className="my-2 ms-2"
              variant="contained"
              sx={{ textTransform: "capitalize" }}
            >
              Upload Excel
            </Button>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell align="center">Email</StyledTableCell>
                    <StyledTableCell align="center">Date</StyledTableCell>
                    <StyledTableCell align="center">Number</StyledTableCell>
                    <StyledTableCell align="center">City</StyledTableCell>
                    <StyledTableCell align="center" className="text-nowrap">
                      Pin Code
                    </StyledTableCell>
                    <StyledTableCell align="center">When</StyledTableCell>
                    <StyledTableCell align="center">Interested</StyledTableCell>
                    <StyledTableCell align="center" className="text-nowrap">
                      Looking Finance
                    </StyledTableCell>
                    <StyledTableCell align="center">Task</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leadCollection
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell component="th" scope="row">
                          {row.customerName}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell align="center" className="text-nowrap">
                          {moment(row.date).format("MMMM Do YYYY")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.number}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.city}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.pinCode}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.when}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.interested}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.lookingFinance}
                        </StyledTableCell>
                        <StyledTableCell align="center" className="d-flex">
                          <IconButton
                            onClick={() => {
                              handleEditLead(row.id, i);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              handleDeleteLead(row.id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={leadCollection.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

            {/* Excel Table */}
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell align="right">Download</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {excelFetch
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell component="th" scope="row">
                        {row?.uploadLeadsCollection[0]?.filename}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                        {moment(row?.uploadLeadsCollection[0]?.upload).format('MMMM Do YYYY')}
                        </StyledTableCell>
                        <StyledTableCell align="right" className="d-flex">
                            <IconButton key={i} href={row?.uploadLeadsCollection[0]?.fileURL}>
                              <DownloadingIcon />
                            </IconButton>                   
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={excelFetch.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}

        <Dialog
          open={userFormModal}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Lead Manager Form
              </Typography>
            </Toolbar>
          </AppBar>
          <LeadForm />
        </Dialog>

        {/* ------------------Excel Modal--------------------- */}
        <Dialog
          open={excelModal}
          onClose={handleExcelClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleExcelClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Upload Excel
              </Typography>
            </Toolbar>
          </AppBar>
          <Box
            component="form"
            noValidate
            sx={{ mt: 3, px: 4, pb: 4, minWidth: 375 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Form.Group controlId="profilePhoto" className="mb-2">
                  <Form.Control
                    type="file"
                    onChange={(e) => {
                      previewFiles(e);
                    }}
                  />
                </Form.Group>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" onClick={uploadExcel}>
                  Upload
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Dialog>
      </Container>
    </>
  );
}
