import { auth, db } from "../../../firebase/config";
import { signInWithEmailAndPassword , signOut, sendPasswordResetEmail } from "firebase/auth";
import { doc, setDoc, getDoc  } from "firebase/firestore";

export function loginUser(email, password) {
  return signInWithEmailAndPassword(auth, email, password);
}

export function logoutUser() {
    return signOut(auth);
  }
  export function getUserRole(id) {
    const docRef = doc(db, "users", id);
    return getDoc(docRef);
  }
  
  export function resetPassword(email){
    return sendPasswordResetEmail(auth,email)
  }