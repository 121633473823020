import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HandymanIcon from "@mui/icons-material/Handyman";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardTab } from "./reduxSlice";
import { loginState } from "../Login/reduxSlice";

export default function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const {userAvlRole} = useSelector(loginState);
  const userAvlRole = window.localStorage.getItem("userAvlRole");
  return (
    <React.Fragment>
      <List component="nav">
        <ListItemButton
          onClick={() => {
            dispatch(setDashboardTab({ tab: "Dashboard" }));
          }}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
        {/* {userAvlRole === "admin" ? (
          <ListItemButton
            onClick={() => {
              dispatch(setDashboardTab({ tab: "Create Lead Form" }));
            }}
          >
            <ListItemIcon>
              <HandymanIcon />
            </ListItemIcon>
            <ListItemText primary="Create Lead Form" />
          </ListItemButton>
        ) : (
          ""
        )} */}
        <ListItemButton
          onClick={() => {
            dispatch(setDashboardTab({ tab: "Lead Manager" }));
          }}
        >
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <ListItemText primary="Add Leads" />
        </ListItemButton>
        {userAvlRole === "admin" ? (
          <ListItemButton
            onClick={() => {
              dispatch(setDashboardTab({ tab: "Users" }));
            }}
          >
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Add Users" />
          </ListItemButton>
        ) : (
          ""
        )}
        <ListItemButton
          onClick={() => {
            dispatch(setDashboardTab({ tab: "Reports" }));
          }}
        >
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Leads Report" />
        </ListItemButton>
        {userAvlRole === "admin" || userAvlRole === "management"? (
          <ListItemButton
            onClick={() => {
              dispatch(setDashboardTab({ tab: "Attendance" }));
            }}
          >
            <ListItemIcon>
              <LayersIcon />
            </ListItemIcon>
            <ListItemText primary="Attendance Report" />
          </ListItemButton>
        ) : (
          ""
        )}
        <ListItemButton
          onClick={() => {
            dispatch(setDashboardTab({ tab: "Settings" }));
          }}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItemButton>
      </List>
    </React.Fragment>
  );
}
