import {  createSlice } from "@reduxjs/toolkit";
const initialState = {
  dashboardTab:'Dashboard'
};



export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboardTab: (state, action) => {
      state.dashboardTab = action.payload.tab;
    },
    clearDashboardTab:(state) =>{
      state.dashboardTab = "Dashboard"
    }
  },
 
});

export const {
  setDashboardTab,
  clearDashboardTab
} = dashboardSlice.actions;

export const dashboardState = (state) => state.dashboard;

export default dashboardSlice.reducer;
