import * as React from "react";
import { styled } from "@mui/material/styles";
import { Form } from "react-bootstrap";
import {
  TextField,
  Grid,
  MenuItem,
  Container,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  Divider,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useDispatch, useSelector } from "react-redux";
import { userState } from "../../Users/reduxSlice";
import dayjs from "dayjs"
import {
  setCustomerName,
  setEmail,
  setDate,
  setNumber,
  setCity,
  setWhen,
  setLookingFinance,
  setInterested,
  setPinCode,
  createLeadAsync,
  leadManagerUserState,
  setUserFormModal,
  updateLeadAsync,
  setUserFormModalFor,
  clearLeadForm
} from "../reduxSlice";
import { useNavigate } from "react-router";

const whenList = ["Immediate", "15 Days", "1 Month", "Later"];
const interestedList = ["3 wheeler", "2 Wheeler", "Cycle"];

export default function App() {
  const [files, setFiles] = React.useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {index, leadCollection, userFormModalFor } = useSelector(leadManagerUserState);

  // const previewFiles = (evt) => {
  //   var files = evt.target.files;

  //   for (let i = 0; i < evt.target.files.length; i++) {
  //     const newImage = evt.target.files[i];

  //     newImage["id"] = Math.random();
  //     console.log(newImage);
  //     dispatch(setPhoto({ newImage }));
  //   }
  // };

  const submitHandle = (event) => {
    event.preventDefault();
    const userAvlUID = window.localStorage.getItem("userAvlUID");
    if(userFormModalFor === "Edit"){
      dispatch(
        updateLeadAsync({
          userAvlUID,
          navigate,
        })
      );
      dispatch(clearLeadForm({}))
      dispatch(setUserFormModalFor({ value: "Add" }));
    }else{
      dispatch(
        createLeadAsync({
          userAvlUID,
          navigate,
        })
      );
    }
    dispatch(setUserFormModal({}))
  };

  return (
    <>
      <Container>
        <Box
          component="form"
          onSubmit={submitHandle}
          noValidate
          sx={{ mt: 3, pb: 4 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="customername"
                label="Customer Name"
                name="customername"
                defaultValue={
                  leadCollection[index]?.customerName
                    ? leadCollection[index].customerName
                    : ""
                }
                onChange={(e) => {
                  dispatch(setCustomerName({ customerName: e.target.value }));
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="email"
                label="Email"
                defaultValue={
                  leadCollection[index]?.email ? leadCollection[index].email : ""
                }
                type="text"
                id="email"
                onChange={(e) => {
                  dispatch(setEmail({ email: e.target.value }));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  defaultValue={leadCollection[index]?.date? (dayjs(leadCollection[index]?.date)): ("")}
                  onChange={(e) => {
                    dispatch(setDate({ date: e }));
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="mobile"
                defaultValue={
                  leadCollection[index]?.number ? leadCollection[index].number : ""
                }
                label="Mobile Number"
                type="text"
                id="mobile"
                onChange={(e) => {
                  dispatch(setNumber({ number: e.target.value }));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="City"
                label="City"
                defaultValue={
                  leadCollection[index]?.city ? leadCollection[index].city : ""
                }
                type="text"
                id="city"
                onChange={(e) => {
                  dispatch(setCity({ city: e.target.value }));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="pincode"
                label="Pin Code"
                defaultValue={
                  leadCollection[index]?.pinCode ? leadCollection[index].pinCode : ""
                }
                type="text"
                id="pincode"
                onChange={(e) => {
                  dispatch(setPinCode({ value: e.target.value }));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                name="When"
                label="When"
                defaultValue={
                  leadCollection[index]?.when ? leadCollection[index].when : ""
                }
                type="text"
                id="when"
                select
                onChange={(e) => {
                  dispatch(setWhen({ when: e.target.value }));
                }}
              >
                {whenList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                select
                name="Interested"
                defaultValue={
                  leadCollection[index]?.interested
                    ? leadCollection[index].interested
                    : ""
                }
                label="Interested"
                type="text"
                id="interested"
                onChange={(e) => {
                  dispatch(setInterested({ interested: e.target.value }));
                }}
              >
                {interestedList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl>
                <FormLabel id="lookingFinance">Looking Finance</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="lookingFinance"
                  name="lookingFinance"
                  defaultValue={
                    leadCollection[index]?.lookingFinance
                      ? leadCollection[index].lookingFinance
                      : ""
                  }
                  onChange={(e) => {
                    dispatch(
                      setLookingFinance({ lookingFinance: e.target.value })
                    );
                  }}
                >
                  <FormControlLabel
                    value="Yes"
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" type="submit" fullWidth>
                {userFormModalFor === "Edit" ? "Update" : "Submit"}
              </Button>
            </Grid>
          </Grid>
          <Divider />
        </Box>
      </Container>
    </>
  );
}
