import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createUser,
  createUserData,
  uploadfile,
  fetchData,
  deleteUserAuth,
  deleteUserData
} from "./reduxAPI";
import { setErrorNotification } from "../Errors/reduxSlice";

const initialState = {
  roleList: [
    {
      value: "admin",
      label: "Admin",
    },
    {
      value: "supervisor",
      label: "Activity Supervisor",
    },
    {
      value: "management",
      label: "Management",
    },
  ],
  countryList: [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antigua &amp; Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia &amp; Herzegovina",
    "Botswana",
    "Brazil",
    "British Virgin Islands",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Cape Verde",
    "Cayman Islands",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Cote D Ivoire",
    "Croatia",
    "Cruise Ship",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Polynesia",
    "French West Indies",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kuwait",
    "Kyrgyz Republic",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Norway",
    "Oman",
    "Pakistan",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Pierre &amp; Miquelon",
    "Samoa",
    "San Marino",
    "Satellite",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "South Africa",
    "South Korea",
    "Spain",
    "Sri Lanka",
    "St Kitts &amp; Nevis",
    "St Lucia",
    "St Vincent",
    "St. Lucia",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor L'Este",
    "Togo",
    "Tonga",
    "Trinidad &amp; Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks &amp; Caicos",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "Uruguay",
    "Uzbekistan",
    "Venezuela",
    "Vietnam",
    "Virgin Islands (US)",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ],
  username: "",
  password: "",
  email: "",
  designation: "",
  number: "",
  address: "",
  city: "",
  state: "",
  country: "",
  role: "",
  reportingManager: "",
  profileLink:"",
  idProofLink:"",
  photo: [],
  idProof: [],
  preview: [],
  id: "",
  loading: false,
  fetchloading: false,
  userCollection:[]
};

export const createUserAsync = createAsyncThunk(
  "user/createUser",
  async ({ password, email, navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const userCredential = await createUser(email, password);
      const user = userCredential.user.uid;
      dispatch(setID({ id: user }));
      // dispatch(profileUploadAsync({navigate}));
      // dispatch(idProofUploadAsync({navigate}));
      dispatch(createUserDataAsync({navigate}));
      return user
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const createUserDataAsync = createAsyncThunk(
  "user/createUserData",
  async ({ all , navigate}, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        id,
        username,
        password,
        email,
        designation,
        number,
        address,
        city,
        state,
        country,
        role,
        reportingManager
      } = getState().user;
      const response = await createUserData(
        id,
        username,
        password,
        email,
        designation,
        number,
        address,
        city,
        state,
        country,
        role,
        reportingManager
      );
      dispatch(fetchDataAsync({navigate}))
      return response
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const profileUploadAsync = createAsyncThunk(
  "user/profileUpload",
  async ({ all,navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { id, photo } = getState().user;
      const location = "Profile"
      const upload = await uploadfile(id, photo, location);
      dispatch(setProfileLink({profileLink: upload}))
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const idProofUploadAsync = createAsyncThunk(
  "user/idProofUpload",
  async ({ all,navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { id, idProof } = getState().user;
      const location = "IdProof"
      const upload = await uploadfile(id, idProof, location);
      dispatch(setIDProofLink({idProofLink: upload}))
      dispatch(createUserDataAsync({navigate}));
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const fetchDataAsync = createAsyncThunk(
  "user/fetchData",
  async ({ all,navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const userList = await fetchData({});
      navigate('/dashboard')
      return userList
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteUserAsync = createAsyncThunk(
  "user/deleteUser",
  async ({ id,navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await deleteUserData(id);
      dispatch(fetchDataAsync({navigate}))
      return response

    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);


export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setID: (state, action) => {
      state.id = action.payload.id;
    },
    setUsername: (state, action) => {
      state.username = action.payload.username;
    },
    setEmail: (state, action) => {
      state.email = action.payload.email;
    },
    setPassword: (state, action) => {
      state.password = action.payload.password;
    },
    setDesignation: (state, action) => {
      state.designation = action.payload.designation;
    },
    setNumber: (state, action) => {
      state.number = action.payload.number;
    },
    setAdress: (state, action) => {
      state.address = action.payload.address;
    },
    setCity: (state, action) => {
      state.city = action.payload.city;
    },
    setState: (state, action) => {
      state.state = action.payload.state;
    },
    setCountry: (state, action) => {
      state.country = action.payload.country;
    },
    setRole: (state, action) => {
      state.role = action.payload.role;
    },
    setProfileLink: (state, action) => {
      state.profileLink = action.payload.profileLink;
    },
    setIDProofLink: (state, action) => {
      state.idProofLink = action.payload.idProofLink;
    },
    setReportingManager: (state, action) => {
      state.reportingManager = action.payload.reportingManager;
    },
    setPhoto: (state, action) => {
      state.photo = action.payload.photo;
    },
    setIDProof: (state, action) => {
      state.idProof = action.payload.idProof;
    },
    setPreveiw: (state, action) => {
      state.preview = action.payload.preview;
    },
    clearUserForm: (state, action) => {
      // state.username = "";
      // state.password = "";
      // state.designation = "";
      // state.number = "";
      // state.address = "";
      // state.city = "";
      // state.state = "";
      // state.country = "";
      // state.role = "";
      // state.reportingManager = "";
      state.photo = [];
      state.idProof = [];
      state.preview = [];
      state.userCollection =[]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataAsync.pending, (state, action) => {
        state.userCollection =[]
        state.fetchloading = true;
      })
      .addCase(fetchDataAsync.fulfilled, (state, action) => {
        state.fetchloading = false;
        action.payload.forEach((doc)=>{
          state.userCollection.push(doc.data())
        })
      })
      .addCase(fetchDataAsync.rejected, (state, action) => {
        state.fetchloading = false;
      })

      .addCase(createUserAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createUserAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createUserAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(createUserDataAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createUserDataAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createUserDataAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(profileUploadAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(profileUploadAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.profileLink = action.payload
      })
      .addCase(profileUploadAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(idProofUploadAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(idProofUploadAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.idProofLink = action.payload
      })
      .addCase(idProofUploadAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(deleteUserAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteUserAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteUserAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  setID,
  setUsername,
  setEmail,
  setPassword,
  setDesignation,
  setProfileLink,
  setIDProofLink,
  setNumber,
  setAdress,
  setCity,
  setState,
  setCountry,
  setRole,
  setReportingManager,
  setPhoto,
  setIDProof,
  setPreveiw,
  clearUserForm,
} = userSlice.actions;

export const userState = (state) => state.user;

export default userSlice.reducer;
