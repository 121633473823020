import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setErrorNotification } from "../Errors/reduxSlice";
import { getLeads } from "./reduxAPI";

const initialState = {
  id: "",
  loading: false,
  selectLead: [],
  leadCollection: [],
};



export const getLeadsAsync = createAsyncThunk(
  "leadReport/getLeads",
  async ({all,userAvlUID,navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const leads = await getLeads(userAvlUID,navigate);
      return leads
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);


export const leadReportSlice = createSlice({
  name: "leadReport",
  initialState,
  reducers: {
    setID: (state, action) => {
      state.id = action.payload.id;
    },
    setselectLead: (state, action) => {
      state.selectLead = action.payload.value;
    },
    clearLeadCollection:(state,action)=>{
      state.leadCollection = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLeadsAsync.pending, (state, action) => {
        state.loading = true;
        state.leadCollection = []
      })
      .addCase(getLeadsAsync.fulfilled, (state, action) => {
        state.loading = false;
       action.payload.forEach((doc)=>{
          state.leadCollection.push(doc.data())
        })
      })
      .addCase(getLeadsAsync.rejected, (state, action) => {
        state.loading = false;
      })
  },
});

export const {
  setID,
  setselectLead,
  clearLeadCollection
} = leadReportSlice.actions;

export const leadReportState = (state) => state.leadReport;

export default leadReportSlice.reducer;
