import { auth, db, storage } from "../../../firebase/config";
import {
  collection,
  getDocs,
} from "firebase/firestore";

export function getUserLeads(index) {
    return getDocs(collection(db,index))
  }

  export function getExcelLeads(index) {
    return getDocs(collection(db, "ExcelLeads", index,"files"))
  }
  

export function getUserAccounts() {
  return getDocs(collection(db, "users"));
}