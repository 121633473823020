import * as React from "react";
// import Mui Style
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
// import MUI Component
import {
  CssBaseline,
  Box,
  Toolbar,
  Typography,
  Divider,
  IconButton,
  Badge,
  Container,
  Link,
  Button,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";

// import MUI icon
import GetAppIcon from '@mui/icons-material/GetApp';
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

// Compant Logo
import ebluLogo from "../../../assets/eblu-logo.png";

// import Component
import SideNavbarComponent from "./listItems";
import DashboardTab from "./Dashboard";
import LeadManagerTab from "../CreateLead/App";
import LeadManagerUserTab from "../AddLead/App";
import UserTab from "../Users/App";
import Attendee from "../Attendance/App";
import LeadReports from "../LeadReports/App";
import AdminReport from "../AdminReports/App";
import AdminAttendance from "../AdminAttendance/App";

// Import React Router
import { useNavigate } from "react-router-dom";

// Import React Redux
import { useDispatch, useSelector } from "react-redux";
import { dashboardState, clearDashboardTab } from "./reduxSlice";
import {
  loginState,
  loginoutUserAsync,
  clearLoadingState,
} from "../Login/reduxSlice";
import { getLeadsAsync, clearLeadCollection } from "../AddLead/reduxSlice";
import {
  setPuchOut,
  punchOutAsync,
  attendanceState,
  setPunchStatus,
} from "../Attendance/reduxSlice";
import { navbarState } from "../Navbar/reduxSlice";

function Copyright(props) {
  return (
    <div className="text-center mt-5">
      <img src={ebluLogo} width="180px" className="img-fluid" />
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        className="mt-0 pt-3"
        {...props}
      >
        {"Powered By © "}
        <Link color="inherit" href="">
          BizConnect CRM
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </div>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function DashboardContent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dashboardTab } = useSelector(dashboardState);
  const { punchStatus } = useSelector(attendanceState);
  const { supportsInstallButton, promptToInstallCb } = useSelector(navbarState);
  const userAvlRole = window.localStorage.getItem("userAvlRole");
  const userAvlUID = window.localStorage.getItem("userAvlUID");
  const statusToken = window.localStorage.getItem("statusToken");
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const logoutHandler = () => {
    dispatch(loginoutUserAsync({ navigate }));
    dispatch(clearDashboardTab({}));
    const statusToken = window.localStorage.getItem("statusToken");
    if (userAvlRole === "supervisor") {
      if (statusToken) {
        dispatch(setPunchStatus({ value: "Not Active" }));
        dispatch(setPuchOut({ value: new Date() }));
        dispatch(punchOutAsync({ userAvlUID, statusToken }));
      }
    }
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              {dashboardTab}
            </Typography>
            {supportsInstallButton ? (
              <Button
                color="info"
                variant="link"
                className="me-3"
                endIcon={<GetAppIcon/>}
                sx={{
                  textTransform: "capitalize",
                }}
                onClick={promptToInstallCb}
              >
                WebApp
              </Button>
            ) : null}
            <IconButton color="inherit" onClick={logoutHandler}>
              <LogoutIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <SideNavbarComponent />
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {userAvlRole === "admin" || userAvlRole === "management"
              ? (dashboardTab === "Dashboard" && <DashboardTab />) ||
                (dashboardTab === "Lead Manager" && <LeadManagerUserTab />) ||
                (dashboardTab === "Users" && <UserTab />) ||
                (dashboardTab === "Reports" && <AdminReport />) ||
                (dashboardTab === "Attendance" && <AdminAttendance />) ||
                (dashboardTab === "Settings" && <DashboardTab />)
              : (dashboardTab === "Dashboard" && <DashboardTab />) ||
                (dashboardTab === "Lead Manager" && <LeadManagerUserTab />) ||
                (dashboardTab === "Users" && <UserTab />) ||
                (dashboardTab === "Reports" && <LeadReports />) ||
                (dashboardTab === "Attendance" && <Attendee />) ||
                (dashboardTab === "Settings" && <DashboardTab />)}
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
