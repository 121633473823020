import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Link,
  Slide,
  Typography,
  TableContainer,
  TablePagination,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
  IconButton,
  Dialog,
  Container,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { attendanceState } from "../Attendance/reduxSlice";
import {
  getUserAccountsAsync,
  clearCollection,
  adminAttendanceState,
  setSelectIndex,
  getUserAttendanceAsync,
  setPreviewCollection,
} from "../AdminAttendance/reduxSlice";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function Orders() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { supervisorCollection, loading, userAttendanceCollection,previewImageCollection } =
    useSelector(adminAttendanceState);
  const userAvlUID = window.localStorage.getItem("userAvlUID");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [expanded, setExpanded] = React.useState(false);
  const [imagePreview, setImagePreview] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handlePreviewClose = () => {
    setImagePreview(false);
  };
  const handlePreviewOpen = () => {
    setImagePreview(true);
  };

  React.useEffect(() => {
    dispatch(getUserAccountsAsync({ userAvlUID, navigate }));
    return () => {
      dispatch(clearCollection({}));
    };
  }, []);

  function handleAttendance() {
    dispatch();
  }

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Check In & Check Out Status of Attendees
      </Typography>

      <div>
        {supervisorCollection?.map((item, i) => {
          return (
            <Accordion
              key={i}
              expanded={expanded === item.username}
              onChange={handleChange(item.username)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${item.username}-content`}
                id={`${item.username}-header`}
                onClick={() => {
                  dispatch(setSelectIndex({ value: item.id }));
                  dispatch(getUserAttendanceAsync({}));
                }}
              >
                <Typography>{item.username}</Typography>
              </AccordionSummary>
              {loading ? (
                <LinearProgress />
              ) : (
                <AccordionDetails>
                  <TableContainer sx={{ maxHeight: 440, width: "100%" }}>
                    <Table sx={{ minWidth: 700 }} size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell nowrap>Status</TableCell>
                          <TableCell>Location</TableCell>
                          <TableCell className="text-nowrap">KM Start</TableCell>
                          <TableCell className="text-nowrap">KM End</TableCell>
                          <TableCell className="text-nowrap">LAST CHECK-IN</TableCell>
                          <TableCell className="text-nowrap">LAST CHECK-OUT</TableCell>
                          <TableCell align="right" className="text-nowrap">View Images</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userAttendanceCollection?.slice(0, 5).map((row, i) => (
                          <TableRow key={i}>
                            <TableCell>{row.punchStatus}</TableCell>
                            <TableCell>{row.liveLocation}</TableCell>
                            <TableCell>{row.kmStart}</TableCell>
                            <TableCell>{row.kmEnd}</TableCell>
                            <TableCell>
                              {moment(row.punchIn).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </TableCell>
                            <TableCell align="right">
                              {moment(row.punchOut).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                onClick={() => {
                                  handlePreviewOpen();
                                  dispatch(
                                    setPreviewCollection({
                                      value: row.galleryCollection,
                                    })
                                  );
                                }}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              )}
            </Accordion>
          );
        })}
      </div>
      <Dialog
        open={imagePreview}
        onClose={handlePreviewClose}
        TransitionComponent={Transition}
      >
        <Container className="my-3" >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handlePreviewClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Swiper
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
                height="400px"
                style={{height:"400px"}}
              >
                {previewImageCollection?.map((item, index) => {
                  return (
                    <SwiperSlide key={index} style={{height:"400px",textAlign:'center'}}>
                      <img src={item}  style={{height:"400px"}} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </React.Fragment>
  );
}
