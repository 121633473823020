import { auth, db, storage } from "../../../firebase/config";
import { createUserWithEmailAndPassword } from "firebase/auth";
import {
  doc,
  setDoc,
  getDoc,
  collection,
  getDocs,
  deleteDoc,
  updateDoc
} from "firebase/firestore";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";

export function createExcelLead(
  userAvlUID,
  uploadLeadsCollection
) {
  let uniqueName = Math.random().toString();
  return setDoc(doc(db, "ExcelLeads", userAvlUID,"files",uniqueName ), {
    id: uniqueName,
    uploadLeadsCollection: uploadLeadsCollection
  });
}

export function createLead(
  userAvlUID,
  customerName,
  email,
  number,
  date,
  city,
  pinCode, 
  when,
  interested,
  lookingFinance,
) {
  let uniqueName = Math.random().toString();
  return setDoc(doc(db, userAvlUID, uniqueName), {
    id: uniqueName,
    customerName:customerName,
    email: email,
    number: number,
    date: date.toString(),
    city: city,
    pinCode:pinCode,
    when: when,
    interested: interested,
    lookingFinance: lookingFinance
  });
}

export function updateLead(
  id,
  userAvlUID,
  customerName,
  email,
  number,
  date,
  city,
  pinCode, 
  when,
  interested,
  lookingFinance,
) {
  return updateDoc(doc(db, userAvlUID, id), {
    customerName:customerName,
    email: email,
    number: number,
    date: date.toString(),
    interested: interested,
    city: city,
    pinCode:pinCode,
    when: when,
    lookingFinance: lookingFinance
  });
}


export function uploadfile(id, file, location) {
  return new Promise((resolve, reject) => {
    let uniqueName = Math.random().toString();
    const fileName = file.name;
    const storageRef = ref(
      storage,
      `/` + id + `/` + location + `/` + uniqueName + `/` + fileName
    );
    const metadata = {
      contentType: null,
    };
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        // Handle unsuccessful uploads
        reject(error);
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
}


export function getLeads(userAvlUID) {
    return getDocs(collection(db, userAvlUID))
  }
export function getExcelLeads(userAvlUID) {
    return getDocs(collection(db, "ExcelLeads", userAvlUID,"files"))
  }


  export function deleteLeads(userAvlUID,id) {
    return deleteDoc(doc(db, userAvlUID, id));
  }
  

  