import {  createSlice } from "@reduxjs/toolkit";
const initialState = {
  showInstallButton: false,
  supportsInstallButton: false,
  promptToInstallCb: undefined,
};

export const navbarSlice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    setInstallBtn: (state, action) => {
      state.supportsInstallButton = action.payload.value;
      state.promptToInstallCb = action.payload.cb;
    },
  },
 
});

export const { setInstallBtn} = navbarSlice.actions;

export const navbarState = (state) => state.navbar;

export default navbarSlice.reducer;
