import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Slide,
  TablePagination,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  Container,
} from "@mui/material";
import React from "react";
import { createSvgIcon } from "@mui/material/utils";
import {
  DataGrid,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  gridSortedRowIdsSelector,
  GridToolbarContainer,
  gridExpandedSortedRowIdsSelector,
  useGridApiContext,
  GridToolbar,
} from "@mui/x-data-grid";

import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from "@mui/icons-material/Close";
import DownloadingIcon from "@mui/icons-material/Downloading"

import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserAccountsAsync,
  getUserLeadsAsync,
  adminReportState,
  setSelectIndex,
  clearCollection,
  getExcelLeadsAsync,
  setselectLead,
} from "./reduxSlice";
import { userState } from "../Users/reduxSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const getRowsFromCurrentPage = ({ apiRef }) =>
  gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);

const getUnfilteredRows = ({ apiRef }) => gridSortedRowIdsSelector(apiRef);

const getFilteredRows = ({ apiRef }) =>
  gridExpandedSortedRowIdsSelector(apiRef);

const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  "SaveAlt"
);

function CustomToolbar() {
  const apiRef = useGridApiContext();

  const handleExport = (options) => apiRef.current.exportDataAsCsv(options);

  const buttonBaseProps = {
    color: "primary",
    size: "small",
    startIcon: <ExportIcon />,
  };

  return (
    <GridToolbarContainer className=" p-3 justify-content-end border-bottom">
      {/* <Button
        {...buttonBaseProps}
        onClick={() =>
          handleExport({ getRowsToExport: getRowsFromCurrentPage })
        }
      >
        Current page rows
      </Button> */}
      <Button
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getFilteredRows })}
        sx={{textTransform:"capitalize"}}
      >
        Filtered Leads CSV
      </Button>
      <Button
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}
        sx={{textTransform:"capitalize"}}
      >
        Export CSV
      </Button>
    </GridToolbarContainer>
  );
}

const headers = [
  { headerName: "Customer Name", field: "customerName",width: 200 },
  { headerName: "Email", field: "email",width: 250  },
  { headerName: "Number", field: "number" ,width: 150 },
  { headerName: "Date", field: "date",width: 200  },
  { headerName: "City", field: "city",width: 150  },
  { headerName: "Pin Code", field: "pinCode",width: 150  },
  { headerName: "When", field: "when" ,width: 150 },
  { headerName: "Interested", field: "interested",width: 150  },
  { headerName: "Looking Finance", field: "lookingFinance",width: 150  },
];


export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [excelView, setExcelView] = React.useState(false);
  const userAvlUID = window.localStorage.getItem("userAvlUID");
  const { usersAccountList,userleadcollection, selectLead, loading, userExcelcollection } = useSelector(adminReportState);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

    const handleClose = () =>{
      setOpen(false)
      dispatch(setSelectIndex({value: ""}))
    }
    const handleOpen = () => {
      setOpen(true)
    }

    const handleExcelClose = () =>{
      setExcelView(false)
      dispatch(setSelectIndex({value: ""}))
    }
    const handleExcelOpen = () => {
      setExcelView(true)
    }
  React.useEffect(() => {
    dispatch(getUserAccountsAsync({ userAvlUID, navigate }));
    return () => {
      dispatch(clearCollection({}))
    };
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <Box>
            <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell align="center">Email</StyledTableCell>
                    <StyledTableCell align="center">Phone No.</StyledTableCell>
                    <StyledTableCell align="center">Role</StyledTableCell>
                    <StyledTableCell align="center">View</StyledTableCell>
                    <StyledTableCell align="center">Uploaded Excel</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {usersAccountList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell component="th" scope="row">
                          {row.username}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.email}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.number}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.role}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <IconButton
                            onClick={() => {
                              dispatch(setSelectIndex({value: row.id}))
                              dispatch(getUserLeadsAsync({}))
                              handleOpen()
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <IconButton
                            onClick={() => {
                              dispatch(setSelectIndex({value: row.id}))
                              dispatch(getExcelLeadsAsync({}))
                              handleExcelOpen()
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={usersAccountList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Grid>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
               Leads Report 
              </Typography>
            </Toolbar>
          </AppBar>
           <Grid item xs={12}>
              <div style={{ height: 500, width: "100%" }}>
                <DataGrid
                  rows={userleadcollection}
                  columns={headers}
                  loading={loading}
                  slots={{ toolbar: CustomToolbar }}
                  pageSizeOptions={[10]}
                  className="px-3"
                  initialState={{
                    ...userleadcollection.initialState,
                    filter: {
                      ...userleadcollection.initialState?.filter,
                    },
                    pagination: {
                      ...userleadcollection.initialState?.pagination,
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                />
              </div>
            </Grid>
        </Dialog>
       
       {/* Excel */}
       <Dialog
          fullScreen
          open={excelView}
          onClose={handleExcelClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleExcelClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
               Leads Report 
              </Typography>
            </Toolbar>
          </AppBar>
              {/* Excel Table */}
         <Container className="mt-3">
         <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell align="right">Download</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userExcelcollection
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <StyledTableRow key={i}>
                        <StyledTableCell component="th" scope="row">
                          Excel {i + 1}
                        </StyledTableCell>
                        <StyledTableCell align="right" className="d-flex">
                          {row.uploadLeadsCollection.map((item, i) => (
                            <IconButton key={i} href={item}>
                              <DownloadingIcon />
                            </IconButton>
                          ))}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={userExcelcollection.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
         </Container>
        </Dialog>
      </Grid>
    </>
  );
}
