import { configureStore } from "@reduxjs/toolkit";
import {combineReducers} from "redux";

import loginReducer from "../components/Component/Login/reduxSlice";
import dashboardReducer from "../components/Component/Dashboard/reduxSlice";
import userReducer from "../components/Component/Users/reduxSlice";
import leadManagerUseReducer from "../components/Component/AddLead/reduxSlice";
import errorNotificationReducer from "../components/Component/Errors/reduxSlice";
import leadReportReducer from "../components/Component/LeadReports/reduxSlice"
import adminReportReducer from "../components/Component/AdminReports/reduxSlice"
import attendanceReducer from "../components/Component/Attendance/reduxSlice"
import adminAttendanceReducer from "../components/Component/AdminAttendance/reduxSlice"
import navbarReducer from "../components/Component/Navbar/reduxSlice"

const reducers = combineReducers({
  login: loginReducer,
  dashboard: dashboardReducer,
  user: userReducer,
  leadManagerUser: leadManagerUseReducer,
  errorNotification: errorNotificationReducer,
  leadReport: leadReportReducer,
  adminReport: adminReportReducer,
  attendance: attendanceReducer,
  adminAttendance: adminAttendanceReducer,
  navbar: navbarReducer
});


export const store = configureStore({
  reducer: reducers,
});
