import * as React from "react";
import { styled } from "@mui/material/styles";
import { Form } from "react-bootstrap";
import {
  TextField,
  Grid,
  MenuItem,
  Container,
  Box,
  Button,
  Typography,
  Stack,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import {
  userState,
  setUsername,
  setEmail,
  setPassword,
  setDesignation,
  setNumber,
  setAdress,
  setCity,
  setState,
  setCountry,
  setRole,
  setReportingManager,
  setPhoto,
  setIDProof,
  setPreveiw
} from "../reduxSlice";


export default function App() {
  const [files, setFiles] = React.useState("");
  const dispatch = useDispatch();

  const { roleList, countryList, preview } =
    useSelector(userState);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Container>
        <Box component="form" noValidate sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={3} lg={2}>
              <Box
                sx={{
                  border: 1,
                  borderStyle: "dashed",
                  height: "75%",
                  mb: 1,
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={preview} className="img-fluid" />
              </Box>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Button variant="contained" component="label">
                  Upload
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={(e) => {
                      dispatch(setPreveiw({preview: URL.createObjectURL(e.target.files[0])}))
                      dispatch(setPhoto({photo: e.target.files[0]}))
                      console.log(e.target.files[0].name)
                    }}
                  />
                </Button>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input hidden accept="image/*" type="file" />
                  <PhotoCamera />
                </IconButton>
              </Stack>
            </Grid> */}
              <Grid item xs={12} sm={6}>
                <TextField
                  name="fullName"
                  required
                  fullWidth
                  id="fullName"
                  label="Full Name"
                  onChange={(e) => {
                    dispatch(setUsername({ username: e.target.value }));
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  onChange={(e) => {
                    dispatch(setEmail({ email: e.target.value }));
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="outlined" fullWidth required>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => {
                      dispatch(setPassword({ password: e.target.value }));
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
              <TextField
              required
                id="role"
                select
                label="Role"
                fullWidth
                onChange={(e) => {
                  dispatch(setRole({ role: e.target.value }));
                }}
              >
                {roleList.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  name="Designation"
                  label="Designation"
                  type="text"
                  id="designation"
                  onChange={(e) => {
                    dispatch(setDesignation({ designation: e.target.value }));
                  }}
                />
              </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="mobile"
                label="Mobile Number"
                type="text"
                id="mobile"
                onChange={(e) => {
                  dispatch(setNumber({ number: e.target.value }));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
                fullWidth
                name="Reporting Manager"
                label="Reporting Manager"
                type="text"
                id="reportingManager"
                onChange={(e) => {
                  dispatch(
                    setReportingManager({ reportingManager: e.target.value })
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                multiline
                rows={1}
                name="addressline"
                label="Address Line"
                type="text"
                id="addressline"
                onChange={(e) => {
                  dispatch(setAdress({ address: e.target.value }));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required
                fullWidth
                name="City"
                label="City"
                type="text"
                id="city"
                onChange={(e) => {
                  dispatch(setCity({ city: e.target.value }));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                name="State"
                label="State"
                type="text"
                id="state"
                onChange={(e) => {
                  dispatch(setState({ state: e.target.value }));
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                select
                name="Country"
                label="Country"
                type="text"
                id="country"
                onChange={(e) => {
                  dispatch(setCountry({ country: e.target.value }));
                }}
              >
                {countryList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
    
            <Grid item xs={12}>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Upload ID Proof</Form.Label>
                <Form.Control
                  type="file"
                  accept=".jpg, .jpeg, .pdf"
                  onChange={(e) => {
                    dispatch(
                      setIDProof({ idProof: e.target.files[0] })
                    );
                  }}
                />
              </Form.Group>
            </Grid>
            {/* <Grid item xs={12}>
              <Form.Group controlId="profilePhoto" className="mb-3">
                <Form.Label>Upload Profile Photo</Form.Label>
                <Form.Control
                  type="file"
                  accept=".jpg, .jpeg"
                  onChange={(e) => {
                    dispatch(setPreveiw({preview: URL.createObjectURL(e.target.files[0])}))
                    dispatch(setPhoto({photo: e.target.files[0]}))
                  }}
                />
              </Form.Group>
            </Grid> */}
          </Grid>
        </Box>
      </Container>
    </>
  );
}
