import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Link,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { attendanceState } from "../Attendance/reduxSlice";
import moment from "moment";

function preventDefault(event) {
  event.preventDefault();
}

export default function Orders() {
  const dispatch = useDispatch();
  const { attendanceCollection } = useSelector(attendanceState);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        Check In & Check Out Status of Attendees
      </Typography>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table  sx={{ minWidth: 700 }} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>KM Start</TableCell>
              <TableCell>KM End</TableCell>
              <TableCell>LAST CHECK-IN</TableCell>
              <TableCell align="right">LAST CHECK-OUT</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {attendanceCollection
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => (
                <TableRow key={i}>
                  <TableCell>{row.punchStatus}</TableCell>
                  <TableCell>{row.liveLocation}</TableCell>
                  <TableCell>{row.kmStart}</TableCell>
                  <TableCell>{row.kmEnd}</TableCell>
                  <TableCell>
                    {moment(row.punchIn).format("MMMM Do YYYY, h:mm:ss a")}
                  </TableCell>
                  <TableCell align="right">
                    {moment(row.punchOut).format("MMMM Do YYYY, h:mm:ss a")}
                  </TableCell>
                </TableRow>
              ))
              }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={attendanceCollection.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  );
}
