import * as React from "react";
import { Route, Routes } from "react-router-dom";

import DashboardComponent from "../Component/Dashboard/App";
import Homepage from "../Homepage/Homepage"
export default function App() {
  return (
    <>
      <Routes>
        <Route>
          <Route path="/" element={<Homepage />} />
          <Route path="/dashboard" element={<DashboardComponent />} />
        </Route>
      </Routes>
    </>
  );
}
