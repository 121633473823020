import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginUser, getUserRole ,logoutUser, resetPassword} from "./reduxAPI";
import { setErrorNotification } from "../Errors/reduxSlice";
const initialState = {
  email: "",
  password: "",
  rememberMe: true,
  loading: false,
  userAvl:false  ,
  userAvlUID: "",
  userAvlRole:""
};


export const loginUserAsync = createAsyncThunk(
  "login/loginUser",
  async (
    { email, password, navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const { rememberMe , userAvl} = getState().login;
      const userCredential = await loginUser(email, password);
      const user = userCredential.user.uid;
      if("panditsyed@gmail.com"){
        window.localStorage.setItem("userAvlRole","admin")
      }
      dispatch(setUserAvl({ userAvl: true }));
      window.localStorage.setItem("userAvlUID",user)
      window.localStorage.setItem("Geo", 'false');
      dispatch(setUserAvlUID({userAvlUID:user}))
      dispatch(getUserRoleAsync({user}))
      navigate('/dashboard')

    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({errorNotification: error.message}))
        console.log(
             error.code,error.message,
        )
      }
      return rejectWithValue(error);
    }
  }
);

export const loginoutUserAsync = createAsyncThunk(
  "login/loginoutUser",
  async (
    { all,navigate },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const response = await logoutUser({});

      dispatch(setUserAvl({ userAvl: false }));
      window.localStorage.setItem("userAvlUID","")
      window.localStorage.setItem("userAvlRole","")
      window.localStorage.setItem("statusToken","")
      window.localStorage.setItem("latitude", "")
      window.localStorage.setItem("longitude", "")
      window.localStorage.setItem("Geo", "false");
      navigate('/')

    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({errorNotification: error.message}))
        console.log(
             error.code,error.message,
        )
      }
      return rejectWithValue(error);
    }
  }
);

export const resetUserPasswordAsync = createAsyncThunk(
  "user/resetPassword",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {email} = getState().login
      const response = await resetPassword(email);
      return response

    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);
export const getUserRoleAsync = createAsyncThunk(
  "user/getUserRole",
  async ({ user }, { dispatch, getState, rejectWithValue }) => {
    try {
      const userData = await getUserRole(user);
      console.log(userData)
      dispatch(setUserAvlRole({ userAvlRole: userData.data().role }));
      window.localStorage.setItem("userAvlRole",userData.data().role)
      return userData

    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);


export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload.email;
    },
    setPassword: (state, action) => {
      state.password = action.payload.password;
    },
    setUserAvl: (state, action) => {
      state.userAvl = action.payload.userAvl;
    },
    setUserAvlUID: (state, action) => {
      state.userAvlUID = action.payload.userAvlUID;
    },
    setUserAvlRole: (state, action) => {
      state.userAvlRole = action.payload.userAvlRole;
    },
    toggleRememberMe: (state, action) => {
      state.rememberMe = !state.rememberMe;
    },
    clearLoadingState: (state, action) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.email = "";
        state.password = "";
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(resetUserPasswordAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(resetUserPasswordAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.email = "";
      })
      .addCase(resetUserPasswordAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(loginoutUserAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loginoutUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.email = "";
        state.password = "";
        state.userAvl = "";
        state.userAvlRole = "";
        state.userAvlUID = "";
      })
      .addCase(loginoutUserAsync.rejected, (state, action) => {
        state.loading = false;
      })
  },
 
});

export const {
  setEmail,
  setPassword,
  toggleRememberMe,
  setUserAvl,
  setUserAvlRole,
  setUserAvlUID,
  clearLoadingState,
} = loginSlice.actions;

export const loginState = (state) => state.login;

export default loginSlice.reducer;
