import * as React from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Link,
  Box,
  Grid,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import GetAppIcon from '@mui/icons-material/GetApp';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ebluLogo from "../../../assets/eblu-logo.png";
import {
  setPassword,
  setEmail,
  toggleRememberMe,
  clearLoadingState,
  loginState,
  loginUserAsync,
  resetUserPasswordAsync,
} from "./reduxSlice";
import { resetPassword } from "./reduxAPI";
import { navbarState } from "../Navbar/reduxSlice";
import { fontSize } from "@mui/system";
function Copyright(props) {
  return (
    <div className="text-center mt-5">
      <img src={ebluLogo} width="180px" className="img-fluid" />
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        className="mt-0 pt-3"
        {...props}
      >
        {"Powered By © "}
        <Link color="inherit" href="">
          BizConnect CRM
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </div>
  );
}
export default function SignInSide() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [resetPassword, setResetPassword] = React.useState(false);
  const { email, password, rememberMe, loading } = useSelector(loginState);
  const {supportsInstallButton, promptToInstallCb} = useSelector(navbarState)

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  React.useEffect(() => {
    dispatch(clearLoadingState({}));
  }, [dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(loginUserAsync({ email, password, navigate }));
  };

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={6}
        sx={{
          backgroundImage:
            "url(https://firebasestorage.googleapis.com/v0/b/biz-crm-f4224.appspot.com/o/websiteImg%2Fbg1.png?alt=media&token=1c78e197-b3a9-4079-9a5e-d22fe6804ae1)",
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        component={Box}
        elevation={6}
        square
        sx={{ placeSelf: "center" }}
      >
        <Box
          sx={{
            my: 8,
            mx: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
           {supportsInstallButton ?
            ( <Button color="info" 
            variant="contained" 
            endIcon={<GetAppIcon/>}
            className="position-absolute"
            size="small"
             sx={{
              left:'auto',
              right:'1em',
              top:'1em',
              fontSize:'0.75rem',
              textTransform:'capitalize'
             }} onClick={promptToInstallCb}>
            WebApp
          </Button>) : null}
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {resetPassword ? (
            <Box
              component="form"
              onSubmit={(e) => {
                e.preventDefault();
                dispatch(resetUserPasswordAsync({}));
              }}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={(e) => {
                  dispatch(setEmail({ email: e.target.value }));
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={()=>{
                  dispatch(resetUserPasswordAsync({}))
                  setResetPassword(false)
                }}
              >
                Reset Password
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2" onClick={()=>{setResetPassword(false)}}>
                    Back to Login 
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          ) : (
            <Box
              component="form"
              onSubmit={submitHandler}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={(e) => {
                  dispatch(setEmail({ email: e.target.value }));
                }}
              />
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  onChange={(e) => {
                    dispatch(setPassword({ password: e.target.value }));
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>

              <FormControlLabel
                control={
                  <Checkbox
                    value="remember"
                    color="primary"
                    defaultChecked
                    onChange={() => {
                      dispatch(toggleRememberMe());
                    }}
                  />
                }
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2" onClick={()=>{setResetPassword(true)}}>
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
