import { db } from "../../../firebase/config";
import Geocode from "react-geocode";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  updateDoc,
} from "firebase/firestore";

export function punchInAPI(
  userAvlUID,
  punchStatus,
  liveLocation,
  punchIn,
  punchOut,
  kmStart,
  kmEnd,
  galleryCollection
) {
  let uniqueName = Math.random().toString();
  window.localStorage.setItem("statusToken", uniqueName);
  return setDoc(doc(db, "attendance", userAvlUID, "checkIn", uniqueName), {
    punchStatus: punchStatus,
    liveLocation: liveLocation,
    punchIn: punchIn.toString(),
    punchOut: punchOut,
    kmStart: kmStart,
    kmEnd: kmEnd,
    galleryCollection: galleryCollection,
  });
}

export function punchOutAPI(
  userAvlUID,
  statusToken,
  punchStatus,
  liveLocation,
  punchIn,
  punchOut,
  kmStart,
  kmEnd,
  galleryCollection
) {
  return updateDoc(doc(db, "attendance", userAvlUID, "checkIn", statusToken), {
    punchStatus: punchStatus,
    liveLocation: liveLocation,
    // punchIn:punchIn.toString(),
    punchOut: punchOut.toString(),
    kmEnd: kmEnd,
    galleryCollection: galleryCollection,
  });
}

export function getGeoLocation(latitude, longitude) {
  Geocode.setApiKey("AIzaSyB9oNVBHPHcz60Tf6b2hPwT-gaVFWtODlE");
  Geocode.setLanguage("en");
  Geocode.setRegion("in");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();
  return Geocode.fromLatLng(latitude, longitude);
}

export function getAttendance(userAvlUID) {
  return getDocs(collection(db, "attendance", userAvlUID, "checkIn"));
}
