import * as React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import LoginComponent from "./components/Component/Login/App";
import Root from "./components/Root/Root";
import "bootstrap/dist/css/bootstrap.css";
import { Alert , IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"
import { useAddToHomescreenPrompt } from "./components/Component/hooks/useAddToHomescreenPrompt";
import { errorNotificationState, setErrorNotification } from "./components/Component/Errors/reduxSlice";
import { useDispatch, useSelector } from "react-redux";
import { setInstallBtn } from "./components/Component/Navbar/reduxSlice";
function App() {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();

  const { errorNotification } = useSelector(errorNotificationState);

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (prompt) {
      dispatch(setInstallBtn({ value: true, cb: promptToInstall }));
    }
  }, [dispatch, prompt, promptToInstall]);

  return (
    <>
      <div className="position-relative">
        <Routes>
          <Route>
            <Route path="login" element={<LoginComponent />} />
            <Route path="*" element={<Root />} />
          </Route>
        </Routes>
        {errorNotification ? (
          // <Collapse in={open}>
            <Alert
              severity="error"
              sx={{
                position: "absolute",
                top: "auto",
                left: "auto",
                right: "10px",
                bottom:'10px',
                zIndex:10000
              }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    dispatch(setErrorNotification({errorNotification: ""}))
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {errorNotification}
            </Alert>
          // </Collapse>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default App;
