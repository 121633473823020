import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setErrorNotification } from "../Errors/reduxSlice";
import { getUserLeads, getUserAccounts ,getExcelLeads} from "./reduxAPI";

const initialState = {
  loading: false,
  index:"",
  selectLead: [],
  userleadcollection: [],
  userExcelcollection: [],
  usersAccountList: [],
};



export const getUserLeadsAsync = createAsyncThunk(
  "adminReport/getUserLeads",
  async ({all,userAvlUID,navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {index} = getState().adminReport
      const userleadcollection = await getUserLeads(index);
      return userleadcollection
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getExcelLeadsAsync = createAsyncThunk(
  "adminReport/getExcelLeads",
  async ({all,userAvlUID,navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {index} = getState().adminReport
      const userleadcollection = await getExcelLeads(index);
      return userleadcollection
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getUserAccountsAsync = createAsyncThunk(
  "adminReport/getUserAccounts",
  async ({all,userAvlUID,navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const userleadcollection = await getUserAccounts(userAvlUID,navigate);
      return userleadcollection
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);


export const adminReportSlice = createSlice({
  name: "adminReport",
  initialState,
  reducers: {
    setSelectIndex: (state, action)=>{
      state.index = action.payload.value
    },
    clearCollection: (state, action) =>{
      state.userleadcollection = [];
      state.usersAccountList = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAccountsAsync.pending, (state, action) => {
        state.loading = true;
        state.usersAccountList = []
      })
      .addCase(getUserAccountsAsync.fulfilled, (state, action) => {
        state.loading = false;
       action.payload.forEach((doc)=>{
          state.usersAccountList.push(doc.data())
        })
      })
      .addCase(getUserAccountsAsync.rejected, (state, action) => {
        state.loading = false;
      })
      
      .addCase(getUserLeadsAsync.pending, (state, action) => {
        state.loading = true;
        state.userleadcollection = []
      })
      .addCase(getUserLeadsAsync.fulfilled, (state, action) => {
        state.loading = false;
       action.payload.forEach((doc)=>{
          state.userleadcollection.push(doc.data())
        })
      })
      .addCase(getUserLeadsAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getExcelLeadsAsync.pending, (state, action) => {
        state.loading = true;
        state.userExcelcollection = []
      })
      .addCase(getExcelLeadsAsync.fulfilled, (state, action) => {
        state.loading = false;
       action.payload.forEach((doc)=>{
          state.userExcelcollection.push(doc.data())
        })
      })
      .addCase(getExcelLeadsAsync.rejected, (state, action) => {
        state.loading = false;
      })
  },
});

export const {
  setSelectIndex,
  clearCollection,
} = adminReportSlice.actions;

export const adminReportState = (state) => state.adminReport;

export default adminReportSlice.reducer;
