import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clearPhotoGallery } from "../AddLead/reduxSlice";
import { setErrorNotification } from "../Errors/reduxSlice";
import {
  punchInAPI,
  punchOutAPI,
  getAttendance,
  getGeoLocation,
  punchGalleryAPI,
} from "./reduxAPI";
const initialState = {
  punchStatus: "Not Active",
  name: "",
  liveLocation: "",
  punchIn: "",
  punchOut: "",
  kmStart: "",
  kmEnd: "",
  attendeeId: "",
  photoStatus: false,
  galleryCollection: [],
  attendanceCollection: [],
  loading: "",
};

export const punchInAsync = createAsyncThunk(
  "attendance/punchIn",
  async ({ userAvlUID, all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        punchStatus,
        liveLocation,
        punchIn,
        punchOut,
        kmStart,
        kmEnd,
        galleryCollection,
      } = getState().attendance;
      dispatch(getGeoLocationAsync({}));
      const response = await punchInAPI(
        userAvlUID,
        punchStatus,
        liveLocation,
        punchIn,
        punchOut,
        kmStart,
        kmEnd,
        galleryCollection
      );
      window.localStorage.setItem("Geo", true);
      dispatch(clearAttendanceState({}));
      dispatch(getAttendanceAsync({ userAvlUID }));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);


export const punchOutAsync = createAsyncThunk(
  "attendance/punchOut",
  async (
    { userAvlUID, statusToken, all },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const {
        punchStatus,
        liveLocation,
        punchIn,
        punchOut,
        kmStart,
        kmEnd,
        galleryCollection,
      } = getState().attendance;
      const response = await punchOutAPI(
        userAvlUID,
        statusToken,
        punchStatus,
        liveLocation,
        punchIn,
        punchOut,
        kmStart,
        kmEnd,
        galleryCollection
      );
      window.localStorage.setItem("statusToken", "");
      dispatch(setPunchStatus({ value: "Not Active" }));
      dispatch(clearAttendanceState({}));
      dispatch(clearPhotoGallery({}));
      dispatch(getAttendanceAsync({ userAvlUID }));
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getGeoLocationAsync = createAsyncThunk(
  "attendance/getGeoLocation",
  async ({ all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const latitude = window.localStorage.getItem("latitude");
      const longitude = window.localStorage.getItem("longitude");
      const response = await getGeoLocation(latitude, longitude);
      const address = response.results[0].formatted_address;
      dispatch(setLiveLocation({ value: address }));
      return address;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getAttendanceAsync = createAsyncThunk(
  "attendance/getAttendance",
  async ({ userAvlUID, all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await getAttendance(userAvlUID);
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const attendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {
    setPunchStatus: (state, action) => {
      state.punchStatus = action.payload.value;
    },
    setName: (state, action) => {
      state.name = action.payload.value;
    },
    setLiveLocation: (state, action) => {
      state.liveLocation = action.payload.value;
    },
    setPuchIn: (state, action) => {
      state.punchIn = action.payload.value;
    },
    setPuchOut: (state, action) => {
      state.punchOut = action.payload.value;
    },
    setKMStart: (state, action) => {
      state.kmStart = action.payload.value;
    },
    setKMEnd: (state, action) => {
      state.kmEnd = action.payload.value;
    },
    setPhotoStatus: (state, action) => {
      state.photoStatus = action.payload.value;
    },
    setGalleryCollection: (state, action) => {
      state.galleryCollection = [
        ...state.galleryCollection,
        action.payload.galleryCollection,
      ];
    },
    clearAttendanceState: (state, action) => {
      state.attendanceCollection = [];
      state.kmStart = "";
      state.kmEnd = "" ;
    },
    clearLoadingState: (state, action) => {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(punchInAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(punchInAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.photoStatus = true;
      })
      .addCase(punchInAsync.rejected, (state, action) => {
        state.loading = false;
      })
      
      .addCase(punchOutAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(punchOutAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.punchIn = "";
        state.punchOut = "";
      })
      .addCase(punchOutAsync.rejected, (state, action) => {
        state.loading = false;
      })
      
      .addCase(getAttendanceAsync.pending, (state, action) => {
        state.loading = true;
        state.attendanceCollection = [];
      })
      .addCase(getAttendanceAsync.fulfilled, (state, action) => {
        state.loading = false;
        action.payload.forEach((doc) => {
          state.attendanceCollection = [
            ...state.attendanceCollection,
            doc.data(),
          ].sort(function (a, b) {
            return new Date(b.punchIn) - new Date(a.punchIn);
          });
        });
      })
      .addCase(getAttendanceAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const {
  setName,
  setPunchStatus,
  setLiveLocation,
  setPuchIn,
  setPuchOut,
  setKMStart,
  setKMEnd,
  clearLoadingState,
  setPhotoStatus,
  setGalleryCollection,
  clearAttendanceState,
} = attendanceSlice.actions;

export const attendanceState = (state) => state.attendance;

export default attendanceSlice.reducer;
