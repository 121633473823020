import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setErrorNotification } from "../Errors/reduxSlice";
import { createLead, getLeads, uploadfile,deleteLeads, updateLead, createExcelLead, getExcelLeads } from "./reduxAPI";
import { punchOutAsync,punchInAsync,setGalleryCollection } from "../Attendance/reduxSlice";

const initialState = {
  customerName: "",
  email: "",
  number: "",
  date: "",
  city: "",
  pinCode: "",
  when: "",
  interested:"",
  lookingFinance: "",
  photoCollection: [],
  photoGallery: [],
  id: "",
  index:"",
  loading: false,
  loadingLeads:false,
  userFormModal:false,
  userFormModalFor:"Add",
  leadCollection: [],
  excelCollection: [],
  uploadLeadsCollection: [],
  excelFetch: [],
};

export const updateLeadAsync = createAsyncThunk(
  "leadManagerUser/updateLead",
  async ({ userAvlUID, all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        id,
        customerName,
        email,
        number,
        date,
        city,
        pinCode,
        when,
        interested,
        lookingFinance
      } = getState().leadManagerUser;
      const response = await updateLead(
        id,
        userAvlUID,
        customerName,
        email,
        number,
        date,
        city,
        pinCode,
        when,
        interested,
        lookingFinance
      );
      dispatch(getLeadsAsync({userAvlUID }))
      dispatch(getExcelLeadsAsync({userAvlUID }))
      dispatch(clearLeadForm({}));

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const createLeadAsync = createAsyncThunk(
  "leadManagerUser/createLead",
  async ({ userAvlUID, all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        customerName,
        email,
        number,
        date,
        city,
        pinCode,
        when,
        interested,
        lookingFinance
      } = getState().leadManagerUser;
      const response = await createLead(
        userAvlUID,
        customerName,
        email,
        number,
        date,
        city,
        pinCode,
        when,
        interested,
        lookingFinance
      );
      dispatch(getLeadsAsync({userAvlUID }))
      dispatch(getExcelLeadsAsync({userAvlUID }))
      dispatch(clearLeadForm({}));

      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const createLeadExcelAsync = createAsyncThunk(
  "leadManagerUser/createExcelLead",
  async ({ userAvlUID, all }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {
        uploadLeadsCollection
      } = getState().leadManagerUser;
      const response = await createExcelLead(
        userAvlUID,
        uploadLeadsCollection
      );
      dispatch(getExcelLeadsAsync({userAvlUID }))
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);


export const fileUploadAsync = createAsyncThunk(
  "leadManagerUser/fileUpload",
  async ({userAvlUID,statusToken,  all, navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { photoCollection, photoGallery } = getState().leadManagerUser;
      const location = "LeadImage";
      let response = await Promise.all(
        // eslint-disable-next-line array-callback-return
        photoCollection.map(async (file, index) => {
          if (file) {
            const downloadURL = await uploadfile(userAvlUID,file,location);
            console.log("**downloadUrl ", index, " ", downloadURL);
            dispatch(setGalleryCollection({ galleryCollection: downloadURL }));
          }
        })
      );
      if(statusToken){
          dispatch(punchOutAsync({ userAvlUID, statusToken }));
      }else{
         dispatch(punchInAsync({ userAvlUID }));
      }
      return response
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const excelUploadAsync = createAsyncThunk(
  "leadManagerUser/uploadfile",
  async ({userAvlUID, all, navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const { excelCollection } = getState().leadManagerUser;
      const location = "UploadExcel";
      let response = await Promise.all(
        // eslint-disable-next-line array-callback-return
        excelCollection.map(async ({file,name,date}, index) => {
          if (file) {
            const downloadURL = await uploadfile(userAvlUID,file,location);
            console.log("**downloadUrl ", index, " ", downloadURL);
            dispatch(setUploadExcelCollection({ filename:name,filedate:date,uploadLeadsCollection: downloadURL }));
          }
        })
      );
      dispatch(createLeadExcelAsync({userAvlUID}))
      return response
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getLeadsAsync = createAsyncThunk(
  "leadManagerUser/getLeads",
  async ({all,userAvlUID,navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const leads = await getLeads(userAvlUID,navigate);
      return leads
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);
export const getExcelLeadsAsync = createAsyncThunk(
  "leadManagerUser/getExcelLeads",
  async ({all,userAvlUID,navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const leads = await getExcelLeads(userAvlUID,navigate);
      return leads

    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const deleteLeadsAsync = createAsyncThunk(
  "leadManagerUser/deleteLeads",
  async ({all,userAvlUID, id,navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const response = await deleteLeads(userAvlUID,id,navigate);
      dispatch(getLeadsAsync({userAvlUID,navigate }))
      return response

    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);


export const leadManagerUserSlice = createSlice({
  name: "leadManagerUser",
  initialState,
  reducers: {
    setID: (state, action) => {
      state.id = action.payload.id;
    },
    setSelectedIndex: (state, action) => {
      state.index = action.payload.index;
    },
    setCustomerName: (state, action) => {
      state.customerName = action.payload.customerName;
    },
    setEmail: (state, action) => {
      state.email = action.payload.email;
    },
    setDate: (state, action) => {
      state.date = action.payload.date;
    },
    setNumber: (state, action) => {
      state.number = action.payload.number;
    },
    setInterested: (state, action) => {
      state.interested = action.payload.interested;
    },
    setLookingFinance: (state, action) => {
      state.lookingFinance = action.payload.lookingFinance;
    },
    setWhen: (state, action) => {
      state.when = action.payload.when;
    },
    setCity: (state, action) => {
      state.city = action.payload.city;
    },
    setPhoto: (state, action) => {
      state.photoCollection = [...state.photoCollection, action.payload.newImage];
    },
    setUserFormModal: (state, action) => {
      state.userFormModal = !state.userFormModal
    },
    setPinCode: (state, action) => {
      state.pinCode = action.payload.value;
    },
    setUserFormModalFor: (state, action) => {
      state.userFormModalFor = action.payload.value;
    },
    setImageGallery: (state, action) => {
      state.photoGallery =  [...state.photoGallery, action.payload.photoGallery];
    },
    setExcelCollection: (state, action) => {
      state.excelCollection =  [...state.excelCollection, {
        file:action.payload.excel,
        name:action.payload.excelName,
        date:action.payload.uploadDate
      }];
    },
    setUploadExcelCollection: (state, action) => {
      state.uploadLeadsCollection =  [...state.uploadLeadsCollection, {
        fileURL:action.payload.uploadLeadsCollection,
        filename:action.payload.filename,
        upload:action.payload.filedate
      }];
    },
    clearLeadForm: (state, action) => {
      state.customerName = "";
      state.date = "";
      state.email = "";
      state.number = "";
      state.when = "";
      state.city = "";
      state.index = "";
      state.lookingFinance = "";
      state.leadCollection = [];
    },
    clearLeadCollection:(state,action)=>{
      state.leadCollection = [];
    },
    clearPhotoGallery:(state,action)=>{
      state.photoGallery = [];
    },
    clearExcelCollection:(state)=>{
      state.excelCollection = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createLeadAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createLeadAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createLeadAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(fileUploadAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fileUploadAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fileUploadAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(excelUploadAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(excelUploadAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.excelCollection = []
      })
      .addCase(excelUploadAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getLeadsAsync.pending, (state, action) => {
        state.loadingLeads = true;
        state.leadCollection = []
      })
      .addCase(getLeadsAsync.fulfilled, (state, action) => {
        state.loadingLeads = false;
       action.payload.forEach((doc)=>{
          state.leadCollection = [...state.leadCollection, doc.data()]
        })
      })
      .addCase(getLeadsAsync.rejected, (state, action) => {
        state.loadingLeads = false;
      })

      .addCase(getExcelLeadsAsync.pending, (state, action) => {
        state.loadingLeads = true;
        state.excelFetch = []
      })
      .addCase(getExcelLeadsAsync.fulfilled, (state, action) => {
        state.loadingLeads = false;
       action.payload.forEach((doc)=>{
          state.excelFetch = [...state.excelFetch, doc.data()]
        })
      })
      .addCase(getExcelLeadsAsync.rejected, (state, action) => {
        state.loadingLeads = false;
      })

      .addCase(deleteLeadsAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deleteLeadsAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteLeadsAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(updateLeadAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(updateLeadAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateLeadAsync.rejected, (state, action) => {
        state.loading = false;
      })
  },
});

export const {
  setID,
  setCustomerName,
  setEmail,
  setDate,
  setExecute,
  setNumber,
  setSupervisorName,
  setAddress,
  setCity,
  setState,
  setCountry,
  setWhen,
  setLookingFinance,
  setPhoto,
  setInterested,
  setPinCode,
  setImageGallery,
  setExcelCollection,
  setUploadExcelCollection,
  setSelectedIndex,
  clearLeadForm,
  setUserFormModal,
  setUserFormModalFor,
  clearLeadCollection,
  clearPhotoGallery,
  clearExcelCollection
} = leadManagerUserSlice.actions;

export const leadManagerUserState = (state) => state.leadManagerUser;

export default leadManagerUserSlice.reducer;
