import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setErrorNotification } from "../Errors/reduxSlice";
import { getUserAttendance, getUserAccounts } from "./reduxAPI";

const initialState = {
  loading: false,
  index:"",
  selectLead: [],
  userAttendanceCollection: [],
  supervisorCollection: [],
  usersAccountList: [],
  previewImageCollection: [],
};



export const getUserAttendanceAsync = createAsyncThunk(
  "adminAttendance/getUserAttendance",
  async ({all,userAvlUID,navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const {index} = getState().adminAttendance
      const collection = await getUserAttendance(index);
      return collection
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const getUserAccountsAsync = createAsyncThunk(
  "adminAttendance/getUserAccounts",
  async ({all,userAvlUID,navigate }, { dispatch, getState, rejectWithValue }) => {
    try {
      const userleadcollection = await getUserAccounts(userAvlUID,navigate);
      return userleadcollection
    } catch (error) {
      if (error.name === "FirebaseError") {
        dispatch(setErrorNotification({ errorNotification: error.message }));
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);


export const adminAttendanceSlice = createSlice({
  name: "adminAttendance",
  initialState,
  reducers: {
    setSelectIndex: (state, action)=>{
      state.index = action.payload.value
    },
    setPreviewCollection: (state, action)=>{
      state.previewImageCollection = action.payload.value
    },
    clearCollection: (state, action) =>{
      // state.userAttendanceCollection = [];
      state.usersAccountList = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAccountsAsync.pending, (state, action) => {
        state.loading = true;
        state.usersAccountList = []
        state.supervisorCollection = []
      })
      .addCase(getUserAccountsAsync.fulfilled, (state, action) => {
        state.loading = false;
       action.payload.forEach((doc)=>{
          state.usersAccountList.push(doc.data())
          if(doc.data().role === "supervisor"){
            state.supervisorCollection = [...state.supervisorCollection, doc.data()]
          }
        })
      })
      .addCase(getUserAccountsAsync.rejected, (state, action) => {
        state.loading = false;
      })

      .addCase(getUserAttendanceAsync.pending, (state, action) => {
        state.loading = true;
        state.userAttendanceCollection = []
      })
      .addCase(getUserAttendanceAsync.fulfilled, (state, action) => {
        state.loading = false;
        action.payload.forEach((doc) => {
          state.userAttendanceCollection = [
            ...state.userAttendanceCollection,
            doc.data(),
          ].sort(function (a, b) {
            return new Date(b.punchIn) - new Date(a.punchIn);
          });
      })})
      .addCase(getUserAttendanceAsync.rejected, (state, action) => {
        state.loading = false;
      })
  },
});

export const {
  setSelectIndex,
  clearCollection,
  setSupervisorAttendance,
  setPreviewCollection
} = adminAttendanceSlice.actions;

export const adminAttendanceState = (state) => state.adminAttendance;

export default adminAttendanceSlice.reducer;
