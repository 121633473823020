import { auth, db, storage } from "../../../firebase/config";
import {
  collection,
  getDocs,
} from "firebase/firestore";

export function getUserAttendance(index) {
    return getDocs(collection(db,"attendance",index,"checkIn"))
  }

export function getUserAccounts() {
  return getDocs(collection(db, "users"));
}