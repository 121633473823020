import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import React from "react";
import { createSvgIcon } from "@mui/material/utils";
import {
  DataGrid,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  gridSortedRowIdsSelector,
  GridToolbarContainer,
  gridExpandedSortedRowIdsSelector,
  useGridApiContext,
  GridToolbar,
} from "@mui/x-data-grid";

import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useDispatch, useSelector } from "react-redux";
import { loginState } from "../Login/reduxSlice";
import {
  clearLeadCollection,
  getLeadsAsync,
  leadReportState,
  setselectLead,
} from "./reduxSlice";

const getRowsFromCurrentPage = ({ apiRef }) =>
  gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);

const getUnfilteredRows = ({ apiRef }) => gridSortedRowIdsSelector(apiRef);

const getFilteredRows = ({ apiRef }) =>
  gridExpandedSortedRowIdsSelector(apiRef);

const ExportIcon = createSvgIcon(
  <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
  "SaveAlt"
);

function CustomToolbar() {
  const apiRef = useGridApiContext();

  const handleExport = (options) => apiRef.current.exportDataAsCsv(options);

  const buttonBaseProps = {
    color: "primary",
    size: "small",
    startIcon: <ExportIcon />,
  };

  return (
    <GridToolbarContainer className=" p-3 justify-content-end border-bottom">
      <Button
        {...buttonBaseProps}
        onClick={() =>
          handleExport({ getRowsToExport: getRowsFromCurrentPage })
        }
      >
        Current page rows
      </Button>
      <Button
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getFilteredRows })}
      >
        Filtered rows
      </Button>
      <Button
        {...buttonBaseProps}
        onClick={() => handleExport({ getRowsToExport: getUnfilteredRows })}
      >
        Unfiltered rows
      </Button>
    </GridToolbarContainer>
  );
}

const headers = [
  { headerName: "Customer Name", field: "customerName",width: 200 },
  { headerName: "Email", field: "email",width: 250  },
  { headerName: "Number", field: "number" ,width: 150 },
  { headerName: "Date", field: "date",width: 200  },
  { headerName: "City", field: "city",width: 150  },
  { headerName: "Pin Code", field: "pinCode",width: 150  },
  { headerName: "When", field: "when" ,width: 150 },
  { headerName: "Interested", field: "interested",width: 150  },
  { headerName: "Looking Finance", field: "lookingFinance",width: 150  },
];

export default function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAvlUID = window.localStorage.getItem("userAvlUID");
  const { leadCollection, selectLead, loading } = useSelector(leadReportState);
  React.useEffect(() => {
    dispatch(getLeadsAsync({ userAvlUID, navigate }));
    return () => {
      dispatch(clearLeadCollection({}));
    };
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        {/* Recent Orders */}
        <Grid item xs={12}>
          <div style={{ height: 500, width: "100%" }}>
            <DataGrid
              rows={leadCollection}
              columns={headers}
              slots={{ toolbar: CustomToolbar }}
              pageSizeOptions={[10]}
              initialState={{
                ...leadCollection.initialState,
                filter: {
                  ...leadCollection.initialState?.filter,
                },
                pagination: {
                  ...leadCollection.initialState?.pagination,
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}
