import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { Col, Row } from "react-bootstrap";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const createData = (status, fname, lname, location, checkin, checkout) => {
  return { status, fname, lname, location, checkin, checkout };
};

const rows = [
  createData(
    "Checked-In",
    "Dhruv update",
    "Sakariya update",
    "Gujarat, Surat,India",
    "Tue, Feb 7, 2023 7.07 PM",
    "Tue, Feb 7, 2023 7.07 PM"
  ),
  createData(
    "Checked-In",
    "Dhruv update",
    "Sakariya update",
    "Gujarat, Surat,India",
    "Tue, Feb 7, 2023 7.07 PM",
    "Tue, Feb 7, 2023 7.07 PM"
  ),
];
export default function App() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = (e) => {
    setOpen(true);
    console.log(e.currentTarget); //table data
  };
  const handleClose = () => setOpen(false);
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>STATUS</StyledTableCell>
              <StyledTableCell align="center">FIRST NAME</StyledTableCell>
              <StyledTableCell align="center">LAST NAME</StyledTableCell>
              <StyledTableCell align="center">LOCATION</StyledTableCell>
              <StyledTableCell align="center">LAST CHECK-IN</StyledTableCell>
              <StyledTableCell align="center">LAST CHECK-OUT</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, i) => (
              <StyledTableRow key={i} onClick={(e) => handleOpen(e)}>
                <StyledTableCell component="th" scope="row">
                  {row.status}
                </StyledTableCell>
                <StyledTableCell align="center">{row.fname}</StyledTableCell>
                <StyledTableCell align="center">{row.lname}</StyledTableCell>
                <StyledTableCell align="center">{row.location}</StyledTableCell>
                <StyledTableCell align="center">{row.checkin}</StyledTableCell>
                <StyledTableCell align="center">{row.checkout}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="border-bottom py-3 bg-light fw-semibold ps-2"
          >
            My Attendee
          </Typography>
          <Row className="mx-0 border-bottom mb-1">
            <Col xs={4}>
              <Box className="d-flex flex-column justify-content-center align-items-start gap-1 py-2">
                <Typography
                  variant="subtitle1"
                  component="h6"
                  className="fw-bold"
                >
                  Date
                </Typography>
                <Typography variant="subtitle2" component="h6">
                  7th February 2023
                </Typography>
              </Box>
            </Col>
            <Col xs={4}>
              <Box className="d-flex flex-column justify-content-center align-items-center gap-1  py-2">
                <Typography
                  variant="subtitle1"
                  component="h6"
                  className="fw-bold"
                >
                  Location
                </Typography>
                <Typography variant="subtitle2" component="h6">
                  7th February 2023
                </Typography>
              </Box>
            </Col>
            <Col xs={4}>
              <Box className="d-flex flex-column justify-content-center align-items-end gap-1  py-2">
                <Typography
                  variant="subtitle1"
                  component="h6"
                  className="fw-bold"
                >
                  Check-In
                </Typography>
                <Typography variant="subtitle2" component="h6">
                  07:30 PM
                </Typography>
              </Box>
            </Col>
          </Row>
        </Box>
      </Modal>
    </>
  );
}
